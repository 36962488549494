import { PerformSearchData, CurrentSearchData, events } from "solostar-lib";
import {
  EventName,
  Scope,
  SearchGroup,
  FacetType,
  SearchField,
  SearchFields,
  SearchFieldEntity,
  SearchFieldFacet,
} from "solostar-graphql";

export const performSearch = (data: PerformSearchData) => {
  events.emit({ name: EventName.SS_PERFORM_SEARCH, data }, "top");
};

type SearchFieldsExtended = SearchFields &
  Record<string, string | string[] | SearchFieldEntity[] | SearchFieldFacet[]>;

export const dedupeSearchGroups = (groups: SearchGroup[]) => {
  const dict: Record<string, SearchGroup> = {};
  groups.forEach((group) => {
    const isSpecific = group.scope && group.scope !== Scope.APP;
    if (dict[group.id] && !isSpecific) {
      return;
    }
    dict[group.id] = group;
  });
  return Object.values(dict);
};

export const getKeywords = (group?: SearchGroup | null): string | null => {
  if (!group) {
    return null;
  }
  const query = group.queries.find((q) => q.facetType === FacetType.KEYWORDS);
  if (!query?.query) {
    return null;
  }
  return query.query;
};

const areEntitiesEqual = (a: SearchFieldEntity, b: SearchFieldEntity) => {
  if (a.text || b.text) {
    return (
      a.text?.toLowerCase() === b.text?.toLowerCase() && a.negated === b.negated
    );
  }
  return a.entity === b.entity;
};

const checkSearchField = (
  field: SearchField,
  current: SearchFieldsExtended
) => {
  if ("value" in field.data) {
    const currentValue = current[field.data.key];
    if (typeof currentValue === "string") {
      return currentValue.includes(field.data.value);
    }
    return false;
  }
  if ("values" in field.data) {
    const currentValues = current[field.data.key] as string[];
    if (Array.isArray(currentValues)) {
      return field.data.values.every((value) => currentValues.includes(value));
    }
    return false;
  }
  if ("entities" in field.data) {
    const currentEntities = current[field.data.key] as SearchFieldEntity[];
    if (!currentEntities || !Array.isArray(currentEntities)) {
      return false;
    }
    return field.data.entities.every(
      (a) => !!currentEntities.find((b) => areEntitiesEqual(a, b))
    );
  }
  if ("facets" in field.data) {
    const currentFacets = current[field.data.type] as SearchFieldFacet[];
    return field.data.facets.every(
      (searchGroupFacet) =>
        !!currentFacets.find(
          (currentFacet) => currentFacet.value === searchGroupFacet.value
        )
    );
  }
  return false;
};

export const isSearchGroupChecked = (
  group: SearchGroup,
  current?: CurrentSearchData
) => {
  /** Using the new API-driven way of updated the LinkedIn search. */
  if (group.fields && current?.searchFields) {
    const allSatisfied = group.fields.every((field) =>
      checkSearchField(field, current.searchFields as SearchFieldsExtended)
    );
    return allSatisfied;
  }
  const query = group.queries[0];
  if (!query) {
    return false;
  }
  return (
    (query.query && current?.[query?.facetType]?.includes(query.query)) || false
  );
};
