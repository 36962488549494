export const isExtension = () => {
  const origins = Array.from(window.location.ancestorOrigins);
  return (
    origins.some((o) => o.startsWith("chrome-extension")) &&
    origins.every((o) => !o.includes("linkedin.com"))
  );
};

export const isExtensionOrPopup = () => {
  const origins = Array.from(window.location.ancestorOrigins);
  return (
    origins.some((o) => o.startsWith("chrome-extension")) &&
    origins.some((o) => o.includes("linkedin.com"))
  );
};
