import { isExtensionOrPopup } from "utils/env";
import { useNavigationHook } from "utils/navigationHook";
import { Button, Input } from "solostar-components";
import { Link, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { FacetType, getSearchGroup, SearchGroup } from "solostar-graphql";

import SidebarHeader from "pages/Sidebar/SidebarHeader";
import Loader from "components/Loader";

import SidebarEditKeywordsSidesheet from "./SidebarEditKeywordsSidesheet";
import { capital } from "case";

const GenerateSearchSuggestion = () => {
  useNavigationHook();
  const navigate = useNavigate();

  const [source, setSource] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState<SearchGroup>();
  const [lastSource, setLastSource] = useState("");
  const [saveOpen, setSaveOpen] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    setLastSource(source);
    console.log(1, source);
    const res = await getSearchGroup(
      { input: { source } },
      { fetchPolicy: "network-only" }
    );
    if (res?.data) {
      setGroup(res.data);
    }
    setLoading(false);
  };

  const keywords = useMemo(() => {
    const field = group?.fields?.find(
      (f) => "key" in f.data && f.data.key === "keywords"
    );
    if (!field || !("value" in field.data)) {
      return undefined;
    }
    return field.data.value;
  }, [group]);

  return (
    <div className="flex flex-col h-screen">
      {isExtensionOrPopup() && <SidebarHeader showClose={true} />}
      <div className="p-8">
        <Button className="mb-6" type="secondary" size="small">
          <Link to={`/`}>Go Back</Link>
        </Button>

        <SidebarEditKeywordsSidesheet
          searchGroup={null}
          onFinishSave={() => navigate("/")}
          defaultValues={{
            id: "1",
            name: capital(lastSource),
            category: "Special Interests",
            queries: [
              {
                facetType: FacetType.KEYWORDS,
                query: keywords ?? "",
                removeIfPresent: true,
                replaceQuery: false,
              },
            ],
          }}
          isOpen={saveOpen}
          onRequestClose={() => {
            setSaveOpen(false);
          }}
        />

        <h1 className="text-xl mb-4 font-bold">Generate Search Suggestions</h1>

        <h3 className="font-semibold">Source Keyword</h3>
        <p className="text-sm mt-1 mb-8">
          Start by providing a "source" keyword that we can use to expand out
          and generate a full-fledged LinkedIn search from. This can be a
          technical topic or concept, language, skillset, or anything relevant
          to the type of candidate you are searching for.
        </p>
        <form
          className="flex gap-x-2 items-center w-full"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <div className="flex-auto">
            <Input
              value={source}
              setValue={setSource}
              placeholder="Ex: kubernetes, seo, python, data analytics"
            />
          </div>
          <Button loading={loading} onClick={onSubmit} className="h-10">
            Generate
          </Button>
        </form>

        {loading && (
          <div className="mt-12 flex flex-col items-center justify-center rounded-md border-gray-200 border bg-gray-100 p-4">
            <Loader />
            <div className="mb-2 mt-8 animate-pulse flex items-center">
              <img
                className="w-8 mr-1 object-contain animate-pulse"
                src="/logo192.png"
                alt="SoloStar Logo"
              />
              <span className="text-xl font-medium">SoloStar</span>
            </div>
            <span className="text-sm">
              SoloStar AI is generating a new LinkedIn search related to{" "}
              <code className="">{source}</code>. 🤔
            </span>
          </div>
        )}

        {!loading && !!keywords && lastSource === source && (
          <div className="mt-12 flex flex-col gap-y-4">
            <p className="text-sm">
              Here's a good search to find more candidates related to{" "}
              <code className="">{source}</code>.
            </p>
            <div className="rounded-md border-gray-200 border bg-gray-100 py-2 px-4">
              <code className="text-xs leading-tight">{keywords}</code>
            </div>
            <Button className="w-fit" onClick={() => setSaveOpen(true)}>
              Save as Search Query
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GenerateSearchSuggestion;
