/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnalyticsEvent {
  EDITED_COMPANY = "EDITED_COMPANY",
  EDITED_INSTITUTION = "EDITED_INSTITUTION",
  EDITED_KEYWORDS = "EDITED_KEYWORDS",
  EDITED_PERSON = "EDITED_PERSON",
  EDITED_TAG = "EDITED_TAG",
  OPENED_AI_FEATURE = "OPENED_AI_FEATURE",
  OPENED_SIDEBAR_FROM_DIVERSIFY_BUTTON = "OPENED_SIDEBAR_FROM_DIVERSIFY_BUTTON",
  OPENED_SIDEBAR_FROM_SEARCH_RESULT = "OPENED_SIDEBAR_FROM_SEARCH_RESULT",
  SEARCHED = "SEARCHED",
  SEARCHED_KEYWORDS = "SEARCHED_KEYWORDS",
  SEARCHED_SIMILAR_PROFILES = "SEARCHED_SIMILAR_PROFILES",
  SEARCHED_SUGGESTION = "SEARCHED_SUGGESTION",
  SIGNED_UP = "SIGNED_UP",
  SUBSCRIPTION_CREATED = "SUBSCRIPTION_CREATED",
  TENANT_CREATED = "TENANT_CREATED",
  USED_AI_FEATURE = "USED_AI_FEATURE",
  VIEWED_PROFILE = "VIEWED_PROFILE",
  VIEWED_SEARCH_RESULT = "VIEWED_SEARCH_RESULT",
}

export enum AnalyticsFacet {
  COUNTRY = "COUNTRY",
  IS_SOLOSTAR_PICK = "IS_SOLOSTAR_PICK",
  IS_URM_CANDIDATE = "IS_URM_CANDIDATE",
  SEARCH_GROUP_CATEGORY = "SEARCH_GROUP_CATEGORY",
  SIMILAR_PROFILE_SEARCH_TYPE = "SIMILAR_PROFILE_SEARCH_TYPE",
  TOP_COMPANIES = "TOP_COMPANIES",
  TOP_INSTITUTIONS = "TOP_INSTITUTIONS",
}

export enum App {
  LINKEDIN_RECRUITER_LITE_SEARCH = "LINKEDIN_RECRUITER_LITE_SEARCH",
  LINKEDIN_RECRUITER_PROFILE = "LINKEDIN_RECRUITER_PROFILE",
  LINKEDIN_RECRUITER_SEARCH_CANDIDATE = "LINKEDIN_RECRUITER_SEARCH_CANDIDATE",
  LINKEDIN_RECRUITER_SEARCH_PROFILE_SIDEBAR = "LINKEDIN_RECRUITER_SEARCH_PROFILE_SIDEBAR",
}

export enum ContactInfoType {
  EMAIL_BUSINESS = "EMAIL_BUSINESS",
  EMAIL_PERSONAL = "EMAIL_PERSONAL",
  LINK_BITBUCKET = "LINK_BITBUCKET",
  LINK_CODEFORCES = "LINK_CODEFORCES",
  LINK_CRUNCHBASE = "LINK_CRUNCHBASE",
  LINK_ENG_BLOG = "LINK_ENG_BLOG",
  LINK_FACEBOOK = "LINK_FACEBOOK",
  LINK_FLICKR = "LINK_FLICKR",
  LINK_GITHUB = "LINK_GITHUB",
  LINK_GITLAB = "LINK_GITLAB",
  LINK_GOOGLE_FINANCE = "LINK_GOOGLE_FINANCE",
  LINK_GOOGLE_SCHOLAR = "LINK_GOOGLE_SCHOLAR",
  LINK_HACKER_RANK = "LINK_HACKER_RANK",
  LINK_INSTAGRAM = "LINK_INSTAGRAM",
  LINK_LEETCODE = "LINK_LEETCODE",
  LINK_LEVELS_FYI = "LINK_LEVELS_FYI",
  LINK_LINKED_IN_PROFILE = "LINK_LINKED_IN_PROFILE",
  LINK_MASTODON = "LINK_MASTODON",
  LINK_MEDIUM = "LINK_MEDIUM",
  LINK_PERSONAL_WEBSITE = "LINK_PERSONAL_WEBSITE",
  LINK_PROFILE = "LINK_PROFILE",
  LINK_QUORA = "LINK_QUORA",
  LINK_REDDIT = "LINK_REDDIT",
  LINK_RESUME = "LINK_RESUME",
  LINK_STACKOVERFLOW = "LINK_STACKOVERFLOW",
  LINK_STACKSHARE = "LINK_STACKSHARE",
  LINK_TWITCH = "LINK_TWITCH",
  LINK_TWITTER = "LINK_TWITTER",
  PHONE_NUMBER_BUSINESS = "PHONE_NUMBER_BUSINESS",
  PHONE_NUMBER_PERSONAL = "PHONE_NUMBER_PERSONAL",
}

export enum EventName {
  SS_ADD_RECRUITER_SEARCH = "SS_ADD_RECRUITER_SEARCH",
  SS_ADD_RECRUITER_SEARCH_RESPONSE = "SS_ADD_RECRUITER_SEARCH_RESPONSE",
  SS_CLOSE_SIDEBAR = "SS_CLOSE_SIDEBAR",
  SS_CONTENT_TAB_VIEWED = "SS_CONTENT_TAB_VIEWED",
  SS_CONTENT_TAB_VIEWED_RESPONSE = "SS_CONTENT_TAB_VIEWED_RESPONSE",
  SS_CURRENT_SEARCH = "SS_CURRENT_SEARCH",
  SS_EMIT_ANALYTICS = "SS_EMIT_ANALYTICS",
  SS_EMIT_ANALYTICS_RESPONSE = "SS_EMIT_ANALYTICS_RESPONSE",
  SS_ESTABLISH_TOP_WINDOW_CONNECTION = "SS_ESTABLISH_TOP_WINDOW_CONNECTION",
  SS_GET_CURRENT_SEARCH = "SS_GET_CURRENT_SEARCH",
  SS_GET_RECRUITER_SEARCH_URL = "SS_GET_RECRUITER_SEARCH_URL",
  SS_GET_RECRUITER_SEARCH_URL_RESPONSE = "SS_GET_RECRUITER_SEARCH_URL_RESPONSE",
  SS_INIT_SIDEBAR = "SS_INIT_SIDEBAR",
  SS_LOGIN = "SS_LOGIN",
  SS_LOGIN_RESPONSE = "SS_LOGIN_RESPONSE",
  SS_OPEN_SIDEBAR = "SS_OPEN_SIDEBAR",
  SS_PARSE_ITEMS = "SS_PARSE_ITEMS",
  SS_PARSE_ITEMS_RESPONSE = "SS_PARSE_ITEMS_RESPONSE",
  SS_PERFORM_SEARCH = "SS_PERFORM_SEARCH",
  SS_REFRESH_CANDIDATE = "SS_REFRESH_CANDIDATE",
  SS_REQUEST_BOOTSTRAP = "SS_REQUEST_BOOTSTRAP",
  SS_REQUEST_BOOTSTRAP_RESPONSE = "SS_REQUEST_BOOTSTRAP_RESPONSE",
}

export enum FacetType {
  COMPANIES = "COMPANIES",
  KEYWORDS = "KEYWORDS",
  TITLES = "TITLES",
}

export enum HTMLElementIdentifierType {
  CLASS = "CLASS",
  ID = "ID",
  SELECTOR = "SELECTOR",
  TAG = "TAG",
}

export enum HTMLTag {
  BUTTON = "BUTTON",
  DIV = "DIV",
  IFRAME = "IFRAME",
  SPAN = "SPAN",
  STYLE = "STYLE",
}

export enum RecruiterSearchFacetType {
  BING_GEO_SWR = "BING_GEO_SWR",
  CURRENT_COMPANY = "CURRENT_COMPANY",
  DEGREE = "DEGREE",
  EDUCATION_SWR = "EDUCATION_SWR",
  SPOKEN_LANGUAGE = "SPOKEN_LANGUAGE",
}

export enum RecruiterSearchTimeScope {
  CURRENT = "CURRENT",
  CURRENT_OR_PAST = "CURRENT_OR_PAST",
  PAST = "PAST",
  PAST_NOT_CURRENT = "PAST_NOT_CURRENT",
}

export enum Scope {
  APP = "APP",
  TENANT = "TENANT",
  USER = "USER",
}

export enum SearchFieldType {
  ENTITY = "ENTITY",
  FACET = "FACET",
  VALUE_MULTIPLE = "VALUE_MULTIPLE",
  VALUE_SINGLE = "VALUE_SINGLE",
}

export enum SubscriptionType {
  FREE = "FREE",
  PREMIUM = "PREMIUM",
  PROFESSIONAL = "PROFESSIONAL",
}

export enum TagTarget {
  COMPANY = "COMPANY",
  INSTITUTION = "INSTITUTION",
  PERSON = "PERSON",
}

export enum TagType {
  CUSTOM = "CUSTOM",
  SELECTIVITY = "SELECTIVITY",
  URM = "URM",
}

export enum TimeSeriesGranularity {
  DAY = "DAY",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export interface AddCompanyInput {
  displayName: string;
  selectivityTagId?: string | null;
  aliases: string[];
  feeders: string[];
  tagIds: string[];
  scope: Scope;
  levels?: CompanyLevelInput[] | null;
}

export interface AddInstitutionInput {
  displayName: string;
  selectivityTagId?: string | null;
  aliases: string[];
  country: string;
  tagIds: string[];
  scope: Scope;
}

export interface AddPersonInput {
  displayName: string;
  tagIds: string[];
  scope: Scope;
  externalId?: string | null;
}

export interface AddRecruiterSearchInput {
  url: string;
  results: any;
}

export interface AddSearchGroupInput {
  name: string;
  category?: string | null;
  subcategory?: string | null;
  queries: SearchQueryInput[];
  fields?: SearchFieldInput[] | null;
  scope: Scope;
}

export interface AddTagInput {
  type: TagType;
  target: TagTarget;
  displayName: string;
  color: string;
  scope: Scope;
  hidden: boolean;
}

export interface AnalyticsFacetInput {
  key: AnalyticsFacet;
  value: string;
}

export interface CheckoutInput {
  domain: string;
  priceId: string;
}

export interface CompanyInput {
  displayName?: string | null;
  selectivityTagId?: string | null;
  aliases?: string[] | null;
  feeders?: string[] | null;
  tagIds?: string[] | null;
  levels?: CompanyLevelInput[] | null;
}

export interface CompanyLevelCompensationInput {
  base?: number | null;
  bonus?: number | null;
  equity?: number | null;
  total?: number | null;
}

export interface CompanyLevelInput {
  name: string;
  years: number;
  compensation?: CompanyLevelCompensationInput | null;
}

export interface DeleteCompanyInput {
  id: string;
}

export interface DeleteInstitutionInput {
  id: string;
}

export interface DeletePersonInput {
  id: string;
}

export interface DeleteSearchGroupInput {
  id: string;
  scope: Scope;
}

export interface DeleteTagInput {
  id: string;
}

export interface EmitAnalyticsInput {
  event: AnalyticsEvent;
  properties?: any | null;
}

export interface GenerateSearchGroupInput {
  source: string;
}

export interface GetAnalyticsInput {
  granularity: TimeSeriesGranularity;
  event: AnalyticsEvent;
  scope: Scope;
  start: any;
  end: any;
  facet?: AnalyticsFacetInput | null;
  tenant?: string | null;
}

export interface GetBootstrapInput {
  app: App;
}

export interface GetRecruiterSearchUrlInput {
  currentResults?: any | null;
  searchFields: any[];
  startNewSearch?: boolean | null;
}

export interface InstitutionInput {
  displayName?: string | null;
  selectivityTagId?: string | null;
  aliases?: string[] | null;
  country?: string | null;
  tagIds?: string[] | null;
}

export interface ManageBillingInput {
  domain: string;
}

export interface ParseDomItemInput {
  app: App;
  html: string;
}

export interface PersonInput {
  displayName?: string | null;
  selectivityTagId?: string | null;
  tagIds?: string[] | null;
  externalId?: string | null;
}

export interface SearchFieldInput {
  type: SearchFieldType;
  data: any;
}

export interface SearchGroupInput {
  name?: string | null;
  category?: string | null;
  subcategory?: string | null;
  queries?: SearchQueryInput[] | null;
  fields?: SearchFieldInput[] | null;
  scope?: Scope | null;
}

export interface SearchQueryInput {
  query: string;
  facetType: FacetType;
  removeIfPresent?: boolean | null;
  replaceQuery?: boolean | null;
}

export interface TagInput {
  type?: TagType | null;
  target?: TagTarget | null;
  displayName?: string | null;
  color?: string | null;
  hidden?: boolean | null;
}

export interface UpdateCompanyInput {
  id: string;
  scope: Scope;
  company: CompanyInput;
}

export interface UpdateInstitutionInput {
  id: string;
  scope: Scope;
  institution: InstitutionInput;
}

export interface UpdatePersonInput {
  id: string;
  scope: Scope;
  person: PersonInput;
}

export interface UpdateSearchGroupInput {
  id: string;
  scope: Scope;
  searchGroup: SearchGroupInput;
}

export interface UpdateTagInput {
  id: string;
  scope: Scope;
  tag: TagInput;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
