import React, { useState, useMemo } from "react";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { useEventListener } from "utils/hooks";
import { CurrentSearchData, colors } from "solostar-lib";
import { emitSearchedKeywords } from "utils/analytics";
import { Button, Input, Badge, Toggle } from "solostar-components";
import { Disclosure } from "@headlessui/react";

import {
  EventName,
  SearchGroup,
  useSearchIdeas,
  useDeleteSearchGroup,
  Scope,
  SearchFieldType,
  SearchIdea,
} from "solostar-graphql";

import {
  SearchCircleIcon,
  PencilIcon,
  TrashIcon,
  MinusIcon,
  PlusIcon,
  ArrowRightIcon,
} from "@heroicons/react/outline";

import {
  performSearch,
  dedupeSearchGroups,
  isSearchGroupChecked,
} from "utils/search";

import EditKeywordsSidesheet from "./SidebarEditKeywordsSidesheet";

const SECTION_IDEAS_LIMIT = 5;
const SELECTED_SECTION = "Selected";

type SearchIdeaSection = { name: string; ideas: SearchIdea[] };

type KeywordOptionProps = {
  id: string;
  displayName: string;
  checked: boolean;
  category: string | null;
  onClick?: (union?: boolean) => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

const SearchIdeaOption: React.FC<{
  idea: SearchIdea;
  setSearchGroup: (group: SearchGroup) => void;
  deleteSearchGroup: (input: any) => void;
  setSidesheetOpen: (open: boolean) => void;
  currentSearch?: CurrentSearchData;
  unionModeEnabled?: boolean;
}> = (props) => {
  const {
    idea,
    currentSearch,
    deleteSearchGroup,
    setSearchGroup,
    setSidesheetOpen,
    unionModeEnabled,
  } = props;
  const ideaId = idea.id + idea.scope;
  const isChecked = isSearchGroupChecked(idea, currentSearch);
  const onEdit = () => {
    setSearchGroup(idea);
    setSidesheetOpen(true);
  };
  return (
    <KeywordOption
      key={ideaId}
      id={ideaId}
      displayName={idea.name}
      checked={isChecked}
      category={idea.category}
      onClick={(holdingShift) => {
        const union = holdingShift || unionModeEnabled;
        if (union && idea.fields) {
          const newFields = idea.fields.map((field) => {
            /**
             * If this is a ValueSingle field (like keywords), specify we should union
             * it with the existing search (OR) instead of intersecting it (AND).
             */
            if (
              field.type === SearchFieldType.VALUE_SINGLE &&
              "value" in field.data
            ) {
              return {
                ...field,
                data: { ...field.data, union: true },
              };
            }
            return field;
          });
          performSearch(newFields);
        } else {
          performSearch(idea.fields || idea.queries);
        }
        emitSearchedKeywords({
          searchGroupId: idea.id,
          name: idea.name,
          category: idea.category || undefined,
        });
      }}
      onDelete={
        idea.scope && idea.scope !== Scope.APP
          ? () =>
              deleteSearchGroup({
                variables: {
                  input: {
                    id: idea.id,
                    scope: idea.scope || Scope.APP,
                  },
                },
              })
          : undefined
      }
      onEdit={onEdit}
    />
  );
};

const KeywordOption: React.FC<KeywordOptionProps> = (props) => {
  const { id, displayName, checked, onClick, onEdit, onDelete } = props;

  /** Hackity hack to bold the "Not" if it's a "negated" boolean. */
  const name = displayName.startsWith("NOT ") ? (
    <>
      <b>
        <u>NOT</u>
      </b>{" "}
      {displayName.split("NOT ").pop()}
    </>
  ) : (
    <>{displayName}</>
  );
  return (
    <div className="flex group items-center my-3 last:mb-0">
      <input
        id={id}
        type="checkbox"
        checked={checked}
        className="rounded text-indigo-700"
        onClick={(evt) => {
          if (onClick) {
            onClick(evt.shiftKey);
          }
        }}
        onChange={() => {
          return null;
        }}
      />
      <label htmlFor={id} className="text-sm ml-2 text-gray-700">
        {name}
      </label>
      <div className="opacity-0 group-hover:opacity-100 transition-all w-12 flex">
        {onEdit && (
          <Button type="text" className="py-0 px-1" onClick={onEdit}>
            <PencilIcon className="h-3 w-3" />
          </Button>
        )}
        {onDelete && (
          <Button type="text" className="py-0 px-1" onClick={onDelete}>
            <TrashIcon className="h-3 w-3 text-red-600" />
          </Button>
        )}
      </div>
    </div>
  );
};

const RESERVED_CATEGORIES = [
  "Similar Institutions",
  "Special Interests",
  "Feeders",
  "Direct Competitors",
];

const SidebarSearchSuggestions: React.FC = () => {
  const { data } = useSearchIdeas();

  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const [unionModeEnabled, setUnionModeEnabled] = useState(false);
  const [deleteSearchGroup] = useDeleteSearchGroup();
  const [filterValue, setFilterValue] = useState("");
  const [searchGroup, setSearchGroup] = useState<null | SearchGroup>(null);
  const [sidesheetOpen, setSidesheetOpen] = useState(false);
  const [currentSearch, setCurrentSearch] = useState<
    CurrentSearchData | undefined
  >();

  useEventListener({
    emit: { name: EventName.SS_GET_CURRENT_SEARCH, data: {} },
    listenName: EventName.SS_CURRENT_SEARCH,
    listenHandler: (msg) => {
      setCurrentSearch(msg.data);
    },
  });

  const ideas = useMemo(() => {
    const deduped = dedupeSearchGroups([...(data || [])]);
    const sorted =
      deduped.sort((a, b) => {
        if (a.name?.startsWith("NOT ") || b.name?.startsWith("NOT ")) {
          return a.name.startsWith("NOT ") ? 1 : -1;
        }
        if (
          a.category &&
          RESERVED_CATEGORIES.includes(a.category) &&
          a.category !== b.category
        ) {
          return -1;
        }
        if (a.scope !== Scope.APP && b.scope === Scope.APP) {
          return -1;
        }
        return a.name.localeCompare(b.name);
      }) || [];

    const sections: SearchIdeaSection[] = [
      { name: SELECTED_SECTION, ideas: [] },
    ];

    sorted.forEach((idea) => {
      const isCompanies = idea.subcategory === "Companies";
      const sectionId = idea.subcategory
        ? isCompanies
          ? "Companies"
          : `${idea.category} - ${idea.subcategory}`
        : null;
      if (sectionId) {
        const section = sections.find((s) => {
          const isChecked = isSearchGroupChecked(idea, currentSearch);
          if (isChecked) {
            return SELECTED_SECTION;
          }
          return s.name === sectionId;
        });
        if (!section) {
          sections.push({ name: sectionId, ideas: [idea] });
        } else {
          section.ideas.push(idea);
        }
      }
    });

    /** Separate out sections <4 options into their own position in the list. */
    const flatSections: Array<SearchIdea | SearchIdeaSection> = [];
    sections.forEach((section) => {
      if (
        section.ideas.length < SECTION_IDEAS_LIMIT &&
        section.name !== SELECTED_SECTION
      ) {
        flatSections.push(...section.ideas);
      } else {
        flatSections.push(section);
      }
    });
    return flatSections;
  }, [data, currentSearch]);

  if (!data) {
    return null;
  }

  return (
    <div className="ss__suggestions p-6">
      <EditKeywordsSidesheet
        searchGroup={searchGroup}
        isOpen={sidesheetOpen}
        onRequestClose={() => setSidesheetOpen(false)}
      />
      <div className="flex justify-between pb-2 mb-4 border-b">
        <div className="flex items-center">
          <SearchCircleIcon className="h-5 w-5 mr-1" />
          <h1 className="text-base font-medium">Search Suggestions</h1>
        </div>
        <div className="">
          <Button
            size="small"
            icon={PlusCircleIcon}
            onClick={() => {
              setSearchGroup(null);
              setSidesheetOpen(true);
            }}
          >
            New
          </Button>
        </div>
      </div>
      <Input
        size="small"
        value={filterValue}
        setValue={setFilterValue}
        placeholder="Search for a suggestion..."
      />
      <div className="flex gap-4 my-3">
        <div className="">
          <p className="text-xs text-gray-700">
            Click on an option below and let the LinkedIn Results page load.
            Click on another Boolean option, and this action will initiate an{" "}
            <code>{unionModeEnabled ? "OR" : "AND"}</code> statement.{" "}
            <button
              className="text-blue-700"
              onClick={() => setInstructionsOpen(!instructionsOpen)}
            >
              See {instructionsOpen ? "less" : "more"}.
            </button>
          </p>
          {instructionsOpen && (
            <React.Fragment>
              <p className="text-xs my-2 text-gray-700">
                Example:{" "}
                <code>
                  'LatinX Keywords {unionModeEnabled ? "OR" : "AND"} Veterans'
                </code>
              </p>
              <p className="text-xs my-2 text-gray-700">
                You can also hold down the Shift key to always initiate an 'OR'
                statement (ex. 'Women's Colleges OR Black Keywords').
              </p>
              <p className="text-xs my-2 text-gray-700">
                Remember, be careful to not apply too many Boolean instances all
                at once! LinkedIn has character count limitations within some of
                their search features, and this can result in a user receiving
                an error message... (ex. the LinkedIn 'Keywords' search feature
                has a 250 character count limit)
              </p>
            </React.Fragment>
          )}
        </div>
        <div className="p-2 min-w-fit flex flex-col items-center bg-gray-100 rounded-md border border-gray-200 h-fit">
          <Toggle enabled={unionModeEnabled} setEnabled={setUnionModeEnabled} />
          <p className="text-xs mt-2">
            <b>{unionModeEnabled ? "OR" : "AND"}</b> Mode
          </p>
        </div>
      </div>
      <form className="">
        {ideas
          .filter((idea) =>
            idea.name.toLowerCase().includes(filterValue.toLowerCase())
          )
          .map((idea) => {
            if ("id" in idea) {
              return (
                <SearchIdeaOption
                  key={idea.id}
                  currentSearch={currentSearch}
                  idea={idea}
                  setSearchGroup={setSearchGroup}
                  deleteSearchGroup={deleteSearchGroup}
                  setSidesheetOpen={setSidesheetOpen}
                  unionModeEnabled={unionModeEnabled}
                />
              );
            }
            if ("ideas" in idea) {
              const defaultOpen = idea.ideas.some((i) =>
                isSearchGroupChecked(i, currentSearch)
              );
              if (idea.name === SELECTED_SECTION) {
                return (
                  <React.Fragment key={idea.name}>
                    {idea.ideas.map((idea) => (
                      <SearchIdeaOption
                        key={idea.id}
                        currentSearch={currentSearch}
                        idea={idea}
                        setSearchGroup={setSearchGroup}
                        deleteSearchGroup={deleteSearchGroup}
                        setSidesheetOpen={setSidesheetOpen}
                        unionModeEnabled={unionModeEnabled}
                      />
                    ))}
                    <div
                      key="divider"
                      className="w-full border-t border-gray-300"
                    />

                    <a
                      className="flex group items-center my-3 last:mb-0 group"
                      href="/ai/search"
                    >
                      <div className="mr-2">✨</div>
                      <div className="text-sm ml-2 text-gray-700 flex">
                        <span className="mr-3 whitespace-nowrap underline">
                          Generate searches with SoloStar AI
                        </span>
                        <Badge color={colors.BRAND}>New</Badge>
                      </div>
                      <div className="opacity-0 group-hover:opacity-100 transition-all w-12 flex">
                        <ArrowRightIcon className="h-3 w-3 ml-2" />
                      </div>
                    </a>
                  </React.Fragment>
                );
              }

              return (
                <Disclosure
                  className="my-3"
                  as="div"
                  key={idea.name}
                  defaultOpen={defaultOpen}
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center hover:text-gray-500">
                        <span className="flex items-center">
                          {open ? (
                            <MinusIcon className="h-4 w-4" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-4 w-4" aria-hidden="true" />
                          )}
                        </span>
                        <span className="text-sm ml-4 text-gray-700 font-bold mr-2">
                          {idea.name}
                        </span>
                        <Badge color={colors.BRAND}>New</Badge>
                      </Disclosure.Button>
                      <Disclosure.Panel className="ml-8">
                        <div>
                          {idea.ideas.map((option) => (
                            <SearchIdeaOption
                              key={option.id}
                              currentSearch={currentSearch}
                              idea={option}
                              setSearchGroup={setSearchGroup}
                              deleteSearchGroup={deleteSearchGroup}
                              setSidesheetOpen={setSidesheetOpen}
                              unionModeEnabled={unionModeEnabled}
                            />
                          ))}
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              );
            }
            return null;
          })}
      </form>
    </div>
  );
};

export default SidebarSearchSuggestions;
